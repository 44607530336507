<template>
  <button v-if="!link" :class="mode">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="mode">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    mode:{
      type: String,
      required: false,
      default: null
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,

    },

  }
}
</script>

<style scoped>
button,
a {
  text-decoration: none;
  padding: 0.4rem 0.5rem;
  font: inherit;
  background-color: #E78237;
  color: white;
  cursor: pointer;
  display: inline-block;
  border-radius: 8px;

}

a:hover,
a:active,
button:hover,
button:active {
  background-color: #C96418;
}

.cancel {
  text-align: center;
  background-color: #777777;
  color: white;
  border: none;
}
.cancel:hover, .cancel:active {
  background-color: #666666 ;
}

.save {
  background-color: #52A96F;
  color: white;
  border: none;
}
.save:hover, .save:active {
  background-color: #428A5A;
}

.EOD {
  background-color: #999999;
}
.EODSelected {
  background-color: #E78237;
}

.delete {
  background-color: #B22725;
  border: none;
}
.delete:hover, .delete:active {
  background-color: #871E1C;
  border: none;
}
.inActive {
  pointer-events: none; /* Disable pointer events */
  cursor: not-allowed; /* Show "not-allowed" cursor on supported browsers */
  opacity: 0.5; /* Optionally reduce the opacity to make it visually disabled */
}
.blue {
  background-color: #0083ca;
}
.blue:hover {
  background: #006AA3;
}
.green {
  background-color: #52A96F;
}
.green:hover {
  background: #499763;
}


</style>